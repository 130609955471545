import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {catchError, Observable, throwError} from "rxjs";
import { environment } from 'src/environments/environment';
import {GeoLookupModel} from "../models/geo-lookup.model";
import { GetDocumentDetailRequest, GetMembershipAgreeementRequest} from "../models/document.request";
import {EventsManager} from "../events/events.manager";
import { MainPageDetailModel } from '../models/plans.model';
@Injectable({
  providedIn: 'root'
})
export class PlansService {

  constructor(private http: HttpClient,private em: EventsManager) { }

  getOffices(lookup:GeoLookupModel):Observable<any> {
    const url:string = `${environment.apiUrl}sales/purchase/portal/get-practices-by-zipcode`;
    return this.http.get(url+'?ZipCode='+lookup.zipCode+'&distance='+lookup.dist+'&organizationId='+lookup.org).pipe(
      catchError((e) => {
        return e;
      })
    )
  }

  getOrgId(site:string) {
    return '5D9799C4-81D0-41CE-8776-86CA5C8CF919';
  }

  getDocumentDetails(request: GetDocumentDetailRequest): Observable<any > {
    const url = `${environment.apiUrl}${environment.treatmentApi}member/get-document-details`;
    return this.http.post<string>(url, request).pipe(
      catchError((e: any) => {
        return e;
      }),
    );
  }

  getOffice(office:string): Observable<MainPageDetailModel>{
    const url:string = `${environment.apiUrl}sales/purchase/portal/get-main-page-detail`;
    //TODO : Replace hardcoded OrganizationId
    return this.http.get<MainPageDetailModel>(url + '?OrganizationId=' + this.em.orgId + '&PracticeId=' + office + '&Brand=generic').pipe(
    catchError((e) => {
      return throwError(() => e);
    })
  )
  }

  getLegalLanguage(): Observable<any>{
    return this.http.get('data/language.json').pipe(
      catchError((e) => {
        return e;
      }),
    );
  }

  getOfficeByVanityName(orgVanity:string, practiceVanity:string): Observable<MainPageDetailModel>{
    const url:string = `${environment.apiUrl}sales/purchase/portal/get-office-by-vanity?orgVanity=${orgVanity}&practiceVanity=${practiceVanity}`;
    return this.http.get<MainPageDetailModel>(url).pipe(
    catchError((e) => {
      return throwError(() => e);
    })
  )
  }

  getMembershipAgreement(request: GetMembershipAgreeementRequest): Observable<Blob > {
    const requestUrl = `${environment.pdfUrl}print/plan-member-agreement?PracticeName=${request.practiceName}&PlanName=${request.planName}`;
    return this.http.get(requestUrl, { responseType: 'blob' });
  }

}
